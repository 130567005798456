import Store from '../store'

export default {
  mounted () {
    this.$nextTick(() => {
      window.setTimeout(this.endLoader, 500)
    })
  },
  methods: {
    endLoader () {
      Store.dispatch('loader/end')
    }
  }
}
