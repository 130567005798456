<template>
  <div class="Err">
    <div class="Err__MainTexts">
      <div class="Err__MainTextsInner">
        <template v-if="isNotFound">
          <p class="Err__Image"><img :src="require(`@images/err/txt_404.png`)" :alt="$t('errors.002')" /></p>
          <p class="Err__Text" v-text="$t('errors.003')" />
        </template>
        <template v-if="isApiError">
          <p class="Err__Text" v-text="$t('errors.001')" />
        </template>
        <template v-if="isLoginError">
          <p class="Err__Text" v-text="$t('errors.004')" />
          <p class="Err__Text"><a :href="`/login?url=${redirectPath}`" v-html="$t('errors.005')" /></p>
        </template>
      </div>
    </div>
    <btn-blue class="Err__ButtonTop" :width="540" @click="$router.push({ name: 'Top', params: {lang: locale } })">
      <img :src="require(`@images/button-top/${locale}/txt_to-top.png`)" :alt="$t('errors.008')">
    </btn-blue>
    <btn-blue class="Err__ButtonOfficial" :width="540" @click="onClickOfficialSite">
      <img :src="require(`@images/button-official/${locale}/txt_to-official.png`)" :alt="$t('common.001')">
    </btn-blue>
  </div>
</template>

<script>
import LocaleMixin from '@/mixins/locale'
import LoaderMixin from '@/mixins/loader'
import BtnBlue from '@/components/global/button-blue.vue'

export default {
  name: 'Err',
  mixins: [LocaleMixin, LoaderMixin],
  components: {
    BtnBlue
  },
  computed: {
    isNotFound () {
      return this.$route.name === 'Error404'
    },
    isApiError () {
      return this.$route.name === 'Error' && !this.$route.query.error
    },
    isLoginError () {
      return this.$route.name === 'Error' && this.$route.query.error === 'login'
    },
    redirectPath () {
      const host = !process.env.VUE_APP_LOCAL_SERVER ? process.env.VUE_APP_URL : location.origin
      const path = `/${this.locale}`
      return `${host}${path}`
    }
  },
  methods: {
    onClickOfficialSite () {
      window.open('https://fire-emblem-heroes.com/', '_blank', 'noopener')
    }
  }
}
</script>

<style lang="scss" scoped>
.Err {
  @at-root {
    & {
      margin-top: 120px;
      margin-bottom: 60px;
    }
    .Err__MainTexts {
      min-height: 300px;
      padding: 40px 20px;
      text-align: center;
      display: table;
      width: 100%;
    }
    .Err__MainTextsInner {
      display: table-cell;
      vertical-align: middle;
    }
    .Err__Text {
      margin-top: 35px;
      font-size: $fs-3xlarge;
      text-shadow: 0 3px 8px rgba(0, 0, 0, .6);
      &:first-child {
        margin-top: 0;
      }
      a {
        text-decoration: underline;
      }
    }
    // NOTE: override generic component
    .Err__ButtonTop {
      margin: 0 auto;
      display: block;
    }
    .Err__ButtonOfficial {
      margin: 30px auto 0;
      display: block;
    }
  }
}
</style>
